import React from "react";
import Spacer from "../components/Spacer";
import Btn from "../components/Btn";
import { useNavigate } from "react-router-dom";
import { useRecoilState, useSetRecoilState } from "recoil";
import { analysisDataState, progress, userDataState } from "../recoil";
import { useEffect } from "react";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { countryList } from "../util/countryList";

export default function ResidentCountry() {
  const [value, setValue] = useRecoilState(userDataState);
  const navigate = useNavigate();
  const setAnalysisState = useSetRecoilState(analysisDataState);
  const { t } = useTranslation();
  const setProgress = useSetRecoilState(progress);
  const [isDropdownOpen, setIsDropdownOpen] = React.useState(false);

  useEffect(() => {
    setProgress(20);
    setAnalysisState();
  }, []);

  if (value.lang === "") {
    navigate("/");
    return <></>;
  }

  return (
    <div className="legalSection">
      <div className="headerText legalHeader">{t("countryResidence")}</div>
      <Spacer top={13} />
      <div className="smText">{t("countryResidence1")}</div>
      <Spacer top={34} />
      <div className="textAreaContainer">
        <textarea
          name="question"
          id="question"
          onFocus={() => setIsDropdownOpen(true)}
          onBlur={() => setIsDropdownOpen(false)}
          value={value.residentCountry}
          onChange={(e) =>
            setValue({
              ...value,
              residentCountry: e.target.value,
            })
          }
          placeholder={t("countryResidencePlaceholder")}
        />
        <div className={`languageDropdown ${isDropdownOpen ? "open" : ""}`}>
          {countryList
            .filter((country) =>
              country.name
                .toLowerCase()
                .includes(value.residentCountry.toLowerCase())
            )
            .map((country) => (
              <div
                className={`lang  ${
                  value.residentCountry.toLowerCase() ===
                  country.name.toLowerCase()
                    ? "active"
                    : ""
                } `}
                onClick={() => {
                  setValue({
                    ...value,
                    residentCountry: country.name,
                  });
                  setIsDropdownOpen(false);
                }}
              >
                {country.name}
              </div>
            ))}
        </div>
      </div>
      <div className="flex al-ce mob">
        <Btn
          customClass={`${
            value.residentCountry < 2 ||
            !countryList.some(
              (country) =>
                country.name.toLowerCase() ===
                value.residentCountry.toLowerCase()
            )
              ? "greyed"
              : ""
          }`}
          onClick={() => {
            if (!(value.residentCountry.length < 2)) {
              navigate("/residentState");
            } else {
              toast.error("Please enter a legal country");
            }
          }}
          text={"nextStep"}
        />
        <Spacer left={12} />
        <div
          className="back"
          onClick={() => {
            navigate("/");
          }}
        >
          {t("back")}
        </div>
      </div>
    </div>
  );
}
