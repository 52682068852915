import React, { useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import LegalQuestion from "../pages/LegalQuestion";
import Loading from "../pages/Loading";
import Analysis from "../pages/Analysis";
import { useTranslation } from "react-i18next";
import { useIntercom } from "react-use-intercom";
import Result from "../pages/Result";
import ResidentCountry from "../pages/ResidentCountry";
import ResidentState from "../pages/ResidentState";
import IncidentCountry from "../pages/IncidentCountry";
import IncidentState from "../pages/IncidentState";
import Email from "../pages/Email";
import Language from "../pages/Language";
import Same from "../pages/Same";

export default function Content() {
 const location = useLocation();
 const { t } = useTranslation();

 const page1Routes = [
  "/",
  "/residentCountry",
  "/same",
  "/residentState",
  "/incidentCountry",
  "/incidentState",
 ];

 const page2Routes = ["/legalQuestion", "/email"];

 const { boot, shutdown } = useIntercom();

 useEffect(() => {
  if (location.pathname === "/analysis") {
   boot();
  } else {
   shutdown();
  }
 }, [location.pathname]);

 const isLoading = location.pathname === "/loading";

 return (
  <div className="content container">
   {
    // If the current path is not /loading, show the navigation
    !isLoading && (
     <div className="left">
      <div className="nav-link">
       <div className={page1Routes.includes(location.pathname) ? "active" : ""}>
        {t("where")}
       </div>
      </div>
      <div className="nav-link">
       <div className={page2Routes.includes(location.pathname) ? "active" : ""}>
        {t("facts")}
       </div>
      </div>
      <div className="nav-link">
       <div
        className={
         location.pathname === "/analysis" || location.pathname === "/result"
          ? "active"
          : ""
        }
       >
        {t("analysis")}
       </div>
      </div>
     </div>
    )
   }
   <div className="right">
    <Routes>
     <Route path="/" element={<Language />} />
     <Route path="/residentCountry" element={<ResidentCountry />} />
     <Route path="/residentState" element={<ResidentState />} />
     <Route path="/same" element={<Same />} />
     <Route path="/incidentCountry" element={<IncidentCountry />} />
     <Route path="/incidentState" element={<IncidentState />} />
     <Route path="/legalQuestion" element={<LegalQuestion />} />
     <Route path="/email" element={<Email />} />
     <Route path="/loading" element={<Loading />} />
     <Route path="/analysis" element={<Analysis />} />
     <Route path="/result" element={<Result />} />
    </Routes>
   </div>
  </div>
 );
}
