import { useEffect, useState } from "react";
import "./App.css";
import Header from "./components/Header";
import { useTranslation } from "react-i18next";
import Content from "./components/Content";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { IntercomProvider } from "react-use-intercom";

const INTERCOM_APP_ID = "sr20pp81";
const API_BASE_URL = "https://api-iam.intercom.io";

function App() {
  const [currentLanguage, setCurrentLanguage] = useState("en");
  const { i18n } = useTranslation();

  useEffect(() => {
    const language = localStorage.getItem("language");
    if (language) {
      i18n.changeLanguage(language);
      setCurrentLanguage(language);
    } else {
      setLanguage("en");
    }
  }, []);

  const setLanguage = (language) => {
    localStorage.setItem("language", language);
    i18n.changeLanguage(language);
    setCurrentLanguage(language);
  };

  return (
    <div className="App">
      <Header currentLanguage={currentLanguage} setCurrentLanguage={setLanguage} />
      <IntercomProvider appId={INTERCOM_APP_ID} apiBaseUrl={API_BASE_URL}>
        <div className="main">
          <ToastContainer hideProgressBar autoClose={3000} />
          <Content />
        </div>
      </IntercomProvider>
    </div>
  );
}

export default App;
