export const Language = ({
 currentLanguage,
 setCurrentLanguage,
 changeLanguageHandler,
}) => {
 return (
  <div className="flex al-ce language">
   <div
    className={`${currentLanguage !== "de" ? "light" : "dark"} pointer`}
    onClick={() => {
     changeLanguageHandler("de");
     setCurrentLanguage("de");
    }}
   >
    DE
   </div>
   <div className={`${currentLanguage !== "de" ? "light" : "dark"} break`}>
    |
   </div>
   <div
    className={`${currentLanguage !== "en" ? "light" : "dark"} pointer`}
    onClick={() => {
     changeLanguageHandler("en");
     setCurrentLanguage("en");
    }}
   >
    EN
   </div>
  </div>
 );
};
