import axios from "axios";

export default async function createConsultation(data) {
    var config = {
        method: "post",
        url: `${process.env.REACT_APP_API_URL}/consultation/new`,

        headers: {
            "Content-Type": "application/json",
        },
        data: data,
    };

    const response = await axios(config);
    console.log(response.data);
    return response.data;
}
