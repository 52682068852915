import React from "react";
import { motion, AnimatePresence } from "framer-motion";
import { ArrowRight, Close } from "../assets/Svg";
import Spacer from "./Spacer";
import { useTranslation } from "react-i18next";

export default function Modal({ title, open, closeModal, text }) {
 const { t } = useTranslation();
 return (
  <AnimatePresence>
   {open && (
    <motion.div
     className="modal"
     initial={{ opacity: 0, x: "100%" }}
     animate={{ opacity: 1, x: 0 }}
     exit={{ opacity: 0 }}
     transition={{ duration: 0.3 }}
    >
     <div className="bg" onClick={closeModal} />
     <div className="modalContent">
      <div onClick={closeModal} className="close flex al-ce jc-ce">
       <Close />
      </div>
      <div className="modalWrapper">
       <div
        className="modal-title"
        dangerouslySetInnerHTML={{ __html: title }}
       />
       <Spacer top={26} />
       <div className="imgContainer">
        <div className="yt-link">
         <ArrowRight />
        </div>
        <img
         src={require("../assets/phoneInHand.png")}
         alt="phoneInHand"
         className="phnInHand"
        />
       </div>
       <Spacer top={22} />
       <div className="modal-text">{text}</div>
       <Spacer top={22} />
       <div className="border" />
       <Spacer top={22} />
       <div className="modal-subTitle">
        <span>AI</span>
        <span>Backtest</span>
       </div>
       <Spacer top={7} />
       <div className="modal-text">{t("backTest")}</div>
       <Spacer top={24} />
       <div className="border" />
       <Spacer top={22} />
       <div className="green">
        <div className="modal-subTitle">
         <span>Science</span>
         <span>Backed</span>
        </div>
       </div>
       <Spacer top={7} />
       <div className="modal-text">{t("scienceBacked")}</div>
      </div>
     </div>
    </motion.div>
   )}
  </AnimatePresence>
 );
}
