import React, { useEffect } from "react";
import Spacer from "../components/Spacer";
import Btn from "../components/Btn";
import { useNavigate } from "react-router-dom";
import { useRecoilState, useSetRecoilState } from "recoil";
import { progress, userDataState } from "../recoil";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { stateList } from "../util/stateList";

export default function IncidentState() {
  const [value, setValue] = useRecoilState(userDataState);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const setProgress = useSetRecoilState(progress);
  const [isDropdownOpen, setIsDropdownOpen] = React.useState(false);

  useEffect(() => {
    setProgress(80);
  }, []);

  if (value.incidentCountry === "") {
    navigate("/incidentCountry");
    return <></>;
  }

  return (
    <div className="legalSection">
      <div className="headerText legalHeader">{t("stateIncident")}</div>
      <Spacer top={13} />
      <div className="smText">{t("stateIncident1")}</div>
      <Spacer top={34} />
      <div className="textAreaContainer">
        <textarea
          name="question"
          onFocus={() => setIsDropdownOpen(true)}
          onBlur={() => setIsDropdownOpen(false)}
          id="question"
          value={value.incidentState}
          onChange={(e) =>
            setValue({
              ...value,
              incidentState: e.target.value,
            })
          }
          placeholder={t("stateResidencePlaceholder")}
        />
        <div className={`languageDropdown ${isDropdownOpen ? "open" : ""}`}>
          {stateList[value.incidentCountry]
            .filter((state) =>
              state.toLowerCase().includes(value.incidentState.toLowerCase())
            )
            .map((state) => (
              <div
                className={`lang  ${
                  value.incidentState.toLowerCase() === state.toLowerCase()
                    ? "active"
                    : ""
                } `}
                onClick={() => {
                  setValue({
                    ...value,
                    incidentState: state,
                  });
                  setIsDropdownOpen(false);
                }}
              >
                {state}
              </div>
            ))}
        </div>
      </div>
      <div className="flex al-ce mob">
        <Btn
          customClass={`${value.incidentState < 2 ? "greyed" : ""}`}
          onClick={() => {
            if (!(value.incidentState.length < 2)) {
              navigate("/legalQuestion");
            } else {
              toast.error("Please enter a legal country");
            }
          }}
          text={"nextStep"}
        />
        <Spacer left={12} />
        <div
          className="back"
          onClick={() => {
            navigate("/incidentCountry");
          }}
        >
          {t("back")}
        </div>
      </div>
    </div>
  );
}
