import React from "react";
import { useTranslation } from "react-i18next";
import CompareItem from "./CompareItem";

export default function Compare({ expanded }) {
 const { t } = useTranslation();
 return (
  <div className="compareGrid">
   <CompareItem
    expanded={expanded}
    HeaderItem={
     <div className="flex jc-sb flinnCompareHeader">
      <div className="flinnText">
       <div>FLINN</div>
      </div>
      <img
       className="law-person2"
       alt="law-person"
       src={require("../assets/lawPerson.png")}
      />
     </div>
    }
    className={"flinnCompare"}
    fee={t("feeValue")}
    speed={t("min")}
    lang={t("yes")}
    res={t("yes")}
    issue={t("yes")}
   />

   {/* <CompareItem
    expanded={expanded}
    HeaderItem={
     <div className="flex al-ce otherLawyersHeader">{t("otherLawyer")}</div>
    }
    className={"hide-mobile otherLawyersCompare"}
    fee={t("otherLawyerFee")}
    speed={t("otherLawyerSpeed")}
    lang={t("no")}
    res={t("no")}
    issue={t("extraCharge")}
   /> */}
  </div>
 );
}
