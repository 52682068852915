import React from "react";
import Spacer from "../components/Spacer";
import Btn from "../components/Btn";
import { useNavigate } from "react-router-dom";
import { useRecoilState, useSetRecoilState } from "recoil";
import { analysisDataState, progress, userDataState } from "../recoil";
import { useEffect } from "react";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { languages } from "../util/languages";

export default function Language() {
 const [value, setValue] = useRecoilState(userDataState);
 const navigate = useNavigate();
 const setAnalysisState = useSetRecoilState(analysisDataState);
 const { t } = useTranslation();
 const setProgress = useSetRecoilState(progress);
 const [isDropdownOpen, setIsDropdownOpen] = React.useState(false);

 useEffect(() => {
  setProgress(10);
  setAnalysisState();
 }, []);

 return (
  <div className="legalSection">
   <div className="headerText legalHeader">{t("langSpeak")}</div>
   <Spacer top={13} />
   <div className="smText">{t("langSpeakSub")}</div>
   <Spacer top={34} />
   <div className="textAreaContainer">
    <textarea
     style={{
      textTransform: "capitalize",
     }}
     onFocus={() => setIsDropdownOpen(true)}
     onBlur={() => setIsDropdownOpen(false)}
     name="language"
     id="question"
     value={value.lang}
     onChange={(e) =>
      setValue({
       ...value,
       lang: e.target.value,
      })
     }
     placeholder={t("langSpeakPlaceholder")}
    />
    <div className={`languageDropdown ${isDropdownOpen ? "open" : ""}`}>
     {languages
      .filter((e) => e.indexOf(value.lang.toLowerCase()) !== -1)
      .sort()
      .map((lang) => (
       <div
        className={`lang  ${value.lang === lang ? "active" : ""} `}
        onClick={() => {
         setValue({
          ...value,
          lang: lang,
         });
         setIsDropdownOpen(false);
        }}
       >
        {lang}
       </div>
      ))}
    </div>
   </div>
   <div className="flex al-ce mob">
    <Btn
     customClass={`${
      value.lang < 2 || !languages.includes(value.lang.toLocaleLowerCase())
       ? "greyed"
       : ""
     }`}
     onClick={() => {
      if (
       !(value.lang.length < 2) &&
       languages.includes(value.lang.toLocaleLowerCase())
      ) {
       navigate("/residentCountry");
      } else {
       toast.error("Please enter a legal language");
      }
     }}
     text={"nextStep"}
    />
   </div>
  </div>
 );
}
