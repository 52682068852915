import React from "react";

export default function Spacer({ top = 0, bottom = 0, left = 0, right = 0 }) {
 return (
  <div
   style={{
    marginTop: top,
    marginBottom: bottom,
    marginLeft: left,
    marginRight: right,
   }}
  />
 );
}
