import React, { useEffect } from "react";
import Btn from "../components/Btn";
import { Download, User } from "../assets/Svg";
import Spacer from "../components/Spacer";
import { useRecoilState } from "recoil";
import { analysisDataState, analysisHeaderState } from "../recoil";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
 PDFDownloadLink,
 StyleSheet,
 Document,
 Text,
 Font,
 View,
 Page,
} from "@react-pdf/renderer";
import { useTranslation } from "react-i18next";
import Charter from "../assets/fonts/Charter-Regular.ttf";
import CircularMedium from "../assets/fonts/CircularStd-Medium.otf";
import CircularBlack from "../assets/fonts/CircularStd-Black.otf";
import getStatus from "../util/getStatus";
import { toast } from "react-toastify";

Font.register({
 family: "Circular",
 fonts: [
  { src: CircularMedium, fontWeight: 500 },
  { src: CircularBlack, fontWeight: 900 },
 ],
});

Font.register({
 family: "Charter",
 fonts: [{ src: Charter, fontWeight: 400 }],
});

const styles = StyleSheet.create({
 page: {
  backgroundColor: "#fff",
  width: "100%",
  padding: 25,
  paddingTop: 50,
  paddingRight: 30,
  position: "relative",
 },
 text: {
  display: "block",
  flex: 1,
  fontSize: 14,
  fontFamily: "Charter",
  lineHeight: 1.5,
  fontWeight: 500,
 },
 headerText: {
  fontSize: 10,
  fontFamily: "Circular",
  fontWeight: 500,
  color: "#868686",
 },
 boldText: {
  color: "#000000",
  fontSize: 10,
  fontFamily: "Circular",
  fontWeight: 900,
 },
 topSection: {
  position: "absolute",
  top: 23,
  right: 25,
  textAlign: "right",
  width: "100%",
 },
 bottomSection: {
  position: "absolute",
  bottom: 19,
  left: 25,
  width: "100%",
 },
});

export default function Result() {
 const [yourAnalysis, setAnalysisHeader] = useRecoilState(analysisHeaderState);
 const [solution, setAnalysisData] = useRecoilState(analysisDataState);
 const navigate = useNavigate();
 const { t } = useTranslation();

 const [params] = useSearchParams();

 const paramsSecret = params.get("secret");

 useEffect(() => {
  (async () => {
   const secret = paramsSecret;
   if (secret) {
    const data = await getStatus({ secret });
    if (data?.status === "PAID") {
     setAnalysisHeader("yourAnalysis");
     setAnalysisData(data?.solution);
    } else {
     navigate("/analysis");
    }
   } else {
    toast.error("Invalid URL");
   }
  })();
 }, [paramsSecret]);

 if (!solution) return null;

 return (
  <>
   <div className="analysisContainer">
    <div className="blur" />
    <div className="headerText">{t([yourAnalysis])}</div>
    <Spacer bottom={17} />
    <div className="analysisTextContainer">
     <div className="analysisText">{solution}</div>
    </div>
   </div>
   <div className="btns flex al-ce pc-only">
    <Btn
     onClick={() => {
      window.open(
       "https://help.hiflinn.com/en/articles/7257750-how-to-consult-a-lawyer",
       "_blank"
      );
     }}
     text={"consultLawyer"}
    />
    <Spacer left={158} />
    <div className="flex al-ce ">
     <PDFDownloadLink
      document={<MyDoc yourAnalysis={solution} />}
      fileName="Solution.pdf"
     >
      {() => <Btn text={"downloadAnalysis"} icon={<Download />} />}
     </PDFDownloadLink>
     <Spacer left={16} />
     <Btn
      onClick={() => {
       window.open("https://help.hiflinn.com/en", "_blank");
      }}
      text={"needHelp"}
      icon={<User />}
     />
    </div>
   </div>

   <div className="btns mob mob-only">
    <PDFDownloadLink
     document={<MyDoc yourAnalysis={solution} />}
     fileName="Solution.pdf"
    >
     {() => <Btn customClass={"downloadBtn"} text={""} icon={<Download />} />}
    </PDFDownloadLink>
    <Btn
     onClick={() => {
      window.open(
       "https://help.hiflinn.com/en/articles/7257750-how-to-consult-a-lawyer",
       "_blank"
      );
     }}
     text={"contactLawyer1"}
    />

    <Btn text={"startOver"} onClick={() => navigate("/")} icon={<User />} />
   </div>
  </>
 );
}

const MyDoc = ({ yourAnalysis }) => {
 return (
  <Document>
   <Page size="A4" style={styles.page}>
    <View style={styles.topSection} fixed>
     <Text style={styles.headerText}>
      <Text style={styles.boldText}>FLINN</Text>| Daily legal questions answered
     </Text>
    </View>
    <Text style={styles.text}>{yourAnalysis}</Text>
    <View style={styles.bottomSection} fixed>
     <Text style={styles.headerText}>Version 0.2</Text>
    </View>
   </Page>
  </Document>
 );
};
