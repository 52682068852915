import React from "react";
import Spacer from "../components/Spacer";
import Btn from "../components/Btn";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { userDataState } from "../recoil";
import { useRecoilState } from "recoil";

export default function Same() {
  const [value, setValue] = useRecoilState(userDataState);
  const { t } = useTranslation();
  const navigate = useNavigate();

  if (value.residentState === "") {
    navigate("/residentState");
    return <></>;
  }

  return (
    <div className="legalSection">
      <div className="headerText legalHeader">{t("samePlace")}</div>
      <Spacer top={46} />
      <div className="flex al-ce mob">
        <Btn
          onClick={() => {
            setValue((prev) => ({
              ...prev,
              incidentCountry: prev.residentCountry,
              incidentState: prev.residentState,
            }));
            navigate("/legalQuestion");
          }}
          customClass={"sameBtn"}
          text={"yes"}
          icon={false}
        />
        <Spacer left={22} />
        <div className="or">{t("or")}</div>
        <Spacer left={22} />
        <Btn
          onClick={() => {
            navigate("/incidentCountry");
          }}
          customClass={"sameBtn"}
          text={"no"}
          icon={false}
        />
      </div>
    </div>
  );
}
