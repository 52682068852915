import React, { useEffect } from "react";
import Spacer from "../components/Spacer";
import Btn from "../components/Btn";

import { useNavigate } from "react-router-dom";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { progress, questionState, userDataState } from "../recoil";
import { toast } from "react-toastify";
import makeid from "../util/generateString";
import { useTranslation } from "react-i18next";
import { ValidateEmail } from "../util/validEmail";
import createConsultation from "../util/createConsultation";
import Loader from "../components/Loader";
import Roller from "../components/Roller";

export default function Email() {
  const setProgress = useSetRecoilState(progress);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [value, setValue] = useRecoilState(userDataState);
  const problem = useRecoilValue(questionState);
  const [loading, setLoading] = React.useState(false);

  const isEmailValid = ValidateEmail(value.email);

  const modifiedValues = {
    lang: value.lang,
    resisdentCountry: value.residentCountry,
    resisdentState: value.residentState,
    incidentCountry: value.incidentCountry,
    incidentState: value.incidentState,
    email: value.email,
  };

  const triggerConsultationStart = async () => {
    if (problem && value) {
      let sessionId = makeid(10);
      try {
        setLoading(true);
        const data = await createConsultation({
          problem,
          ...modifiedValues,
          session: sessionId,
        });
        if (data) {
          localStorage.setItem("secret", data.createdConsultation.secret);
          navigate("/loading");
        }
      } catch (error) {
        toast.error("Something went wrong, please try again");
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    setProgress(0);
  }, []);
  return (
    <div className="legalSection">
      <div
        className="headerText legalHeader"
        dangerouslySetInnerHTML={{
          __html: t("off"),
        }}
      ></div>
      <Spacer top={13} />
      <div className="smText">{t("emailText")}</div>
      <Spacer top={34} />
      <div className="textSection">
        <div className="inputContainer">
          <input
            type="email"
            name="email"
            className="input"
            value={value.email}
            onChange={(e) =>
              setValue({
                ...value,
                email: e.target.value,
              })
            }
            placeholder={t("emailPlaceholder")}
          />
        </div>
      </div>
      <div className="flex al-ce mob">
        <Btn
          customClass={`${!isEmailValid ? "greyed" : "noHover"}`}
          onClick={() => {
            if (isEmailValid && problem.length >= 10) {
              triggerConsultationStart();
            } else {
              navigate("/");
              toast.error("Please enter a legal question");
            }
          }}
          loader={<Roller />}
          loading={loading}
          text={t("nextStep")}
        />
        <Spacer left={12} />
        <div
          onClick={() => {
            if (problem.length >= 10) {
              triggerConsultationStart();
            } else {
              navigate("/");
              toast.error("Please enter a legal question");
            }
          }}
          className="back"
        >
          {t("skip")}
        </div>
      </div>
    </div>
  );
}
