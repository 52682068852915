import React from "react";

export default function Roller({ black = false }) {
 return (
  <div className="loadingio-spinner-rolling-3keew3bkh0l">
   <div className="ldio-dm0swqp3eze">
    <div
     style={{
      borderColor: black ? "black" : "white",
      borderTopColor: "transparent",
     }}
    ></div>
   </div>
  </div>
 );
}
