export const Logo = () => (
 <svg
  width="52"
  height="13"
  viewBox="0 0 52 13"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
 >
  <path
   d="M3.248 13H0.386V0.237999H8.612V2.938H3.23V5.638H7.964V8.23H3.248V13ZM18.9685 13H10.6165V0.237999H13.4605V10.282H18.9685V13ZM23.6918 13H20.8118V0.237999H23.6918V13ZM37.5424 13H34.5364L29.2804 4.612V13H26.4544V0.237999H29.9104L34.6984 7.996V0.237999H37.5424V13ZM51.3763 13H48.3703L43.1143 4.612V13H40.2883V0.237999H43.7443L48.5323 7.996V0.237999H51.3763V13Z"
   fill="black"
  />
 </svg>
);

export const Arrow = () => (
 <svg
  width="18"
  height="18"
  viewBox="0 0 18 18"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
 >
  <path
   d="M5.25 12.75L12.75 5.25M12.75 5.25H5.25M12.75 5.25V12.75"
   stroke="white"
   strokeWidth="2"
   strokeLinecap="round"
   strokeLinejoin="round"
  />
 </svg>
);

export const ArrowDown = ({ fill = "black" }) => (
 <svg
  width="20"
  height="22"
  viewBox="0 0 20 22"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
 >
  <path
   d="M5 8.25L10 13.75L15 8.25"
   stroke={fill}
   strokeWidth="2"
   strokeLinecap="round"
   strokeLinejoin="round"
  />
 </svg>
);

export const ArrowUp = () => (
 <svg
  width="18"
  height="18"
  viewBox="0 0 18 18"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
 >
  <path
   d="M13.5 11.25L9 6.75L4.5 11.25"
   stroke="white"
   stroke-width="2"
   stroke-linecap="round"
   stroke-linejoin="round"
  />
 </svg>
);

export const ArrowRight = () => (
 <svg
  width="20"
  height="20"
  viewBox="0 0 20 20"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
 >
  <path
   d="M7.5 15L12.5 10L7.5 5"
   stroke="white"
   strokeWidth="2"
   strokeLinecap="round"
   strokeLinejoin="round"
  />
 </svg>
);

export const Close = () => (
 <svg
  width="16"
  height="16"
  viewBox="0 0 16 16"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
 >
  <path
   d="M2.31188 13.6827C2.7398 14.1033 3.46509 14.0961 3.85675 13.6972L7.99818 9.55575L12.1396 13.6972C12.5458 14.1033 13.2638 14.1033 13.6772 13.6827C14.0979 13.2547 14.1051 12.5512 13.6917 12.1378L9.55756 7.99637L13.6917 3.86221C14.1051 3.44879 14.1051 2.73801 13.6772 2.31734C13.2566 1.89667 12.5458 1.89667 12.1396 2.30283L7.99818 6.44425L3.85675 2.30283C3.46509 1.90392 2.73255 1.88942 2.31188 2.31734C1.89121 2.73801 1.90571 3.4633 2.29737 3.86221L6.44605 7.99637L2.29737 12.145C1.90571 12.5367 1.89121 13.2692 2.31188 13.6827Z"
   fill="#16161A"
  />
 </svg>
);

export const Download = () => (
 <svg
  width="18"
  height="18"
  viewBox="0 0 18 18"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
 >
  <path
   d="M15.75 15.75H2.25M13.5 8.25L9 12.75M9 12.75L4.5 8.25M9 12.75V2.25"
   stroke="white"
   stroke-width="2"
   stroke-linecap="round"
   stroke-linejoin="round"
  />
 </svg>
);

export const User = () => (
 <svg
  width="18"
  height="18"
  viewBox="0 0 18 18"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
 >
  <path
   d="M2.25 15C4.00184 13.1419 6.38026 12 9 12C11.6197 12 13.9982 13.1419 15.75 15M12.375 5.625C12.375 7.48896 10.864 9 9 9C7.13604 9 5.625 7.48896 5.625 5.625C5.625 3.76104 7.13604 2.25 9 2.25C10.864 2.25 12.375 3.76104 12.375 5.625Z"
   stroke="white"
   stroke-width="2"
   stroke-linecap="round"
   stroke-linejoin="round"
  />
 </svg>
);

export const Shield = () => (
 <svg
  width="14"
  height="14"
  viewBox="0 0 14 14"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
 >
  <path
   d="M5.37414 8.45805C5.55627 8.80485 5.91991 9.04139 6.33881 9.04139H7.58325C8.22758 9.04139 8.74992 8.51905 8.74992 7.87472C8.74992 7.23039 8.22758 6.70805 7.58325 6.70805H6.41659C5.77225 6.70805 5.24992 6.18572 5.24992 5.54139C5.24992 4.89706 5.77225 4.37472 6.41659 4.37472H7.66103C8.07992 4.37472 8.44357 4.61126 8.62569 4.95805M6.99992 3.49972V4.37472M6.99992 9.04139V9.91639M11.6666 6.99972C11.6666 9.86298 8.54344 11.9455 7.40708 12.6084C7.27794 12.6837 7.21336 12.7214 7.12223 12.741C7.05151 12.7561 6.94833 12.7561 6.87761 12.741C6.78648 12.7214 6.7219 12.6837 6.59276 12.6084C5.4564 11.9455 2.33325 9.86298 2.33325 6.99972V4.20999C2.33325 3.74361 2.33325 3.51042 2.40953 3.30997C2.47691 3.13289 2.58641 2.97488 2.72855 2.84961C2.88946 2.70781 3.1078 2.62593 3.54449 2.46217L6.6722 1.28928C6.79348 1.2438 6.85411 1.22107 6.91649 1.21205C6.97182 1.20406 7.02801 1.20406 7.08334 1.21205C7.14573 1.22107 7.20636 1.2438 7.32763 1.28928L10.4553 2.46217C10.892 2.62593 11.1104 2.70781 11.2713 2.84961C11.4134 2.97488 11.5229 3.13289 11.5903 3.30997C11.6666 3.51042 11.6666 3.74361 11.6666 4.20999V6.99972Z"
   stroke="#32C850"
   stroke-linecap="round"
   stroke-linejoin="round"
  />
 </svg>
);
