import React, { useEffect } from "react";
import Spacer from "../components/Spacer";
import Loader from "../components/Loader";
import { useNavigate } from "react-router-dom";
import getStatus from "../util/getStatus";
import { useSetRecoilState } from "recoil";
import { analysisDataState, analysisHeaderState } from "../recoil";
import { sleep } from "../util/sleep";
import { useTranslation } from "react-i18next";

export default function Loading() {
 const [progress, setProgress] = React.useState(0);
 const navigate = useNavigate();
 const setAnalysisHeader = useSetRecoilState(analysisHeaderState);
 const [initialApiCall, setInitialApiCall] = React.useState(false);
 const setAnalysisData = useSetRecoilState(analysisDataState);

 const { t } = useTranslation();

 useEffect(() => {
  const interval = setInterval(() => {
   setProgress((prevProgress) => {
    //(prevProgress >= 90 ? 90 : prevProgress + 1)

    if (prevProgress >= 100) {
     return 76;
    }

    if (prevProgress < 100) {
     return prevProgress + 1;
    }
   });
  }, 100);

  if (progress >= 60) if (!initialApiCall) setInitialApiCall(true);

  return () => {
   clearInterval(interval);
  };
 }, [progress, navigate, initialApiCall]);

 useEffect(() => {
  (async () => {
   const secret = localStorage.getItem("secret");
   if (initialApiCall) {
    let data = {
     status: "START",
    };
    do {
     data = await getStatus({ secret });

     if (data.status === "AI_PROCESSED") {
      setAnalysisHeader("yourAnalysis");
      setAnalysisData(data);
      await sleep(1000);
      navigate("/analysis");
     }

     if (data.status === "ERROR") {
      setAnalysisHeader("wrong");
      await sleep(1000);
      navigate("/analysis");
     }

     await sleep(4000);
    } while (data.status !== "AI_PROCESSED" && data.status !== "ERROR");
   }
  })();
 }, [initialApiCall]);

 const getProgressHeader = () => {
  if (progress < 25) {
   return t("loadingHeader1");
  } else if (progress < 50) {
   return t("loadingHeader2");
  } else if (progress < 75) {
   return t("loadingHeader3");
  } else {
   return t("loadingHeader4");
  }
 };

 const getProgressSubHeader = () => {
  if (progress < 25) {
   return t("loadingSubHeader1");
  } else if (progress < 50) {
   return t("loadingSubHeader2");
  } else if (progress < 75) {
   return t("loadingSubHeader3");
  } else {
   return t("loadingSubHeader4");
  }
 };

 return (
  <div className="flex col al-ce jc-ce loading-content">
   <div className="headerText flex jc-ce">{getProgressHeader()}</div>
   <Spacer bottom={10} />
   <div className="loadingText">{getProgressSubHeader()}</div>
   <Spacer bottom={30} />
   <div
    style={{
     gap: "19px",
     width: "100%",
    }}
    className="flex barContainer"
   >
    <Loader progress={(progress / 25) * 100} />
    <Loader progress={((progress - 25) / 25) * 100} />
    <Loader bg="#FFCE6F" progress={((progress - 50) / 25) * 100} />
    <Loader progress={((progress - 75) / 25) * 100} />
   </div>
  </div>
 );
}
