import axios from 'axios';

export default async function getStatus(data) {

    var config = {
        method: 'post',
        url: `${process.env.REACT_APP_API_URL}/consultation/status`,
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    };

    const response = await axios(config);
    return response.data;
}