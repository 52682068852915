import React from "react";
import { Logo } from "../assets/Svg";
import { Language } from "./Language";
import { useTranslation } from "react-i18next";
import Btn from "./Btn";
import { Link, useNavigate } from "react-router-dom";
import Progress from "./Progress";

export default function Header({ currentLanguage, setCurrentLanguage }) {
 const { i18n } = useTranslation();

 const navigate = useNavigate();

 const changeLanguageHandler = (e) => {
  const languageValue = e;
  i18n.changeLanguage(languageValue);
 };

 return (
  <div>
   <div className="border flex al-ce">
    <div className="container header flex al-ce jc-sb">
     <Link className="logo" to={"/"}>
      <Logo />
     </Link>

     <div className="flex al-ce">
      <Language
       currentLanguage={currentLanguage}
       setCurrentLanguage={setCurrentLanguage}
       changeLanguageHandler={changeLanguageHandler}
      />
      <Btn
       text={"startBtn"}
       customClass={"pc-only"}
       onClick={() => navigate("/")}
      />
     </div>
    </div>
   </div>
   <Progress />
  </div>
 );
}
