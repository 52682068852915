import React from "react";
import { useTranslation } from "react-i18next";
import { Arrow } from "../assets/Svg";

export default function Btn({
 large,
 text,
 loader,
 customClass,
 loading,
 icon = <Arrow />,
 ...props
}) {
 const { t } = useTranslation();

 return (
  <div
   className={`btn ${large ? "btnLarge" : ""} ${
    customClass ? customClass : ""
   } `}
   {...props}
  >
   {loading ? loader : t([text])}
   {icon}
  </div>
 );
}
