import React, { useEffect, useState } from "react";
import Btn from "../components/Btn";
import { ArrowDown, ArrowUp, Shield } from "../assets/Svg";
// import { Download, User } from "../assets/Svg";
import Spacer from "../components/Spacer";
import { useRecoilValue } from "recoil";
import {
  analysisDataState,
  analysisHeaderState,
  questionState,
} from "../recoil";
import { useNavigate } from "react-router-dom";
import {
  PDFDownloadLink,
  StyleSheet,
  Document,
  Text,
  Font,
  View,
  Page,
} from "@react-pdf/renderer";
import { useTranslation } from "react-i18next";
import Charter from "../assets/fonts/Charter-Regular.ttf";
import CircularMedium from "../assets/fonts/CircularStd-Medium.otf";
import CircularBlack from "../assets/fonts/CircularStd-Black.otf";
import Roller from "../components/Roller";
import createConsultation from "../util/createConsultation";
import makeid from "../util/generateString";
import { motion } from "framer-motion";
import Compare from "../components/Compare";
import CompareItem from "../components/CompareItem";
import getStatus from "../util/getStatus";

Font.register({
  family: "Circular",
  fonts: [
    { src: CircularMedium, fontWeight: 500 },
    { src: CircularBlack, fontWeight: 900 },
  ],
});

Font.register({
  family: "Charter",
  fonts: [{ src: Charter, fontWeight: 400 }],
});

const styles = StyleSheet.create({
  page: {
    backgroundColor: "#fff",
    width: "100%",
    padding: 25,
    paddingTop: 50,
    paddingRight: 30,
    position: "relative",
  },
  text: {
    display: "block",
    flex: 1,
    fontSize: 14,
    fontFamily: "Charter",
    lineHeight: 1.5,
    fontWeight: 500,
  },
  headerText: {
    fontSize: 10,
    fontFamily: "Circular",
    fontWeight: 500,
    color: "#868686",
  },
  boldText: {
    color: "#000000",
    fontSize: 10,
    fontFamily: "Circular",
    fontWeight: 900,
  },
  topSection: {
    position: "absolute",
    top: 23,
    right: 25,
    textAlign: "right",
    width: "100%",
  },
  bottomSection: {
    position: "absolute",
    bottom: 19,
    left: 25,
    width: "100%",
  },
});

export default function Analysis() {
  const yourAnalysis = useRecoilValue(analysisHeaderState);
  //  const solution = useRecoilValue(analysisDataState);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [loading, setLoading] = React.useState(false);
  const [expanded, setExpanded] = React.useState(false);
  const [status, setStatus] = useState();

  //  const handlePayment = async () => {
  //   if (problem) {
  //    setLoading(true);
  //    const data = await createConsultation({
  //     problem,
  //     session: makeid(10),
  //    });
  //    if (data) {
  //     setData(data);
  //     localStorage.setItem("secret", data.createdConsultation.secret);
  //    }
  //    setLoading(false);
  //   }
  //  };

  useEffect(() => {
    (async () => {
      const secret = localStorage.getItem("secret");
      const localStatus = await getStatus({ secret });
      setStatus(localStatus);
    })();
  }, []);

  const solution = status?.solution;

  const blurredText =
    "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Error, ipsa dolor magni sint qui adipisci ea autem neque itaque nostrum perferendis, assumenda ab voluptates ipsum? Nihil inventore dicta voluptates deleniti. \nDoloribus aspernatur voluptatem error? Iste facilis totam, eaque accusamus, laborum nobis quam itaque eos sapiente obcaecati blanditiis, praesentium ratione. Numquam mollitia sint recusandae facilis cum porro fuga aut sit. Nisi! \n\nBlanditiis, reprehenderit adipisci? Quo placeat consequatur nam deleniti maiores, at laudantium soluta odio et dolorem sapiente deserunt vitae quod nobis sequi aliquid cupiditate non nihil rerum cum commodi velit voluptatem? \n\nBlanditiis, reprehenderit adipisci? Quo placeat consequatur nam deleniti maiores, at laudantium soluta odio et dolorem sapiente deserunt vitae quod nobis sequi aliquid cupiditate non nihil rerum cum commodi velit voluptatem? \nDoloribus aspernatur voluptatem error? Iste facilis totam, eaque accusamus, laborum nobis quam itaque eos sapiente obcaecati blanditiis, praesentium ratione. Numquam mollitia sint recusandae facilis cum porro fuga aut sit. Nisi!";

  if (!status)
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
        }}
      >
        <Roller black />
      </div>
    );

  return (
    <>
      <div className="analysisContainer analysisPage">
        {/* <div className="blur" /> */}
        <div className="headerText">{t([yourAnalysis])}</div>
        <Spacer bottom={17} />

        {status.status === "PAID" ? (
          <div className="analysisText pb-2">{solution}</div>
        ) : (
          <>
            <div className="analysisTextContainer pc-only">
              <div className="analysisText">{solution}</div>
              <div className="blurredText">{blurredText.substring(0, 675)}</div>
            </div>
            <div className="analysisTextContainer mob-only">
              <div className="analysisText">{solution}</div>
              <div className="blurredText">{blurredText.substring(0, 80)}</div>
            </div>
            <div className="pc-only comparision-item">
              <Spacer top={34} />
              <Compare expanded={expanded} />
              <Spacer top={25} />
              <motion.div
                initial={{
                  y: -82,
                }}
                animate={{
                  y: expanded ? 0 : -82,
                }}
              >
                <div className="flex">
                  <Spacer left={21} />
                  <Btn
                    style={{
                      paddingLeft: "26px",
                      paddingRight: "26px",
                      backgroundColor: "#32C850",
                      minWidth: "240px",
                      border: "none",
                    }}
                    customClass="noHover"
                    onClick={() => {
                      setLoading(true);
                      window.open(status?.paymentUrl, "_self");
                    }}
                    loading={loading}
                    loader={<Roller />}
                    text={t("startAnalysis")}
                    icon={false}
                  />
                  <Spacer left={12} />
                  <Btn
                    text={expanded ? "foldIn" : "allOptions"}
                    onClick={() => setExpanded(!expanded)}
                    icon={expanded ? <ArrowUp /> : <ArrowDown fill={"#fff"} />}
                  />
                </div>
                <Spacer top={8} />
                <div className="flex">
                  <Spacer left={21} />
                  <Shield />
                  <Spacer left={4} />
                  <div className="money-back">{t("moneyBack")}</div>
                </div>
              </motion.div>
            </div>
            <div className="mob-only mob-comparision-item">
              <CompareItem
                expanded={true}
                animate={false}
                HeaderItem={
                  <div className="flex jc-sb flinnCompareHeader">
                    <div className="flinnText">
                      <div>FLINN</div>
                    </div>
                    <img
                      className="law-person2"
                      alt="law-person"
                      src={require("../assets/lawPerson.png")}
                    />
                  </div>
                }
                className={"flinnCompare"}
                fee={t("feeValue")}
                speed={t("min")}
                lang={t("yes")}
                res={t("yes")}
                issue={t("yes")}
              />
              <div className="mob-only flex mob">
                <Spacer left={21} />
                <Btn
                  style={{
                    paddingLeft: "26px",
                    paddingRight: "26px",
                    backgroundColor: "#32C850",
                    minWidth: "240px",
                    border: "none",
                  }}
                  customClass="noHover"
                  onClick={() => {
                    setLoading(true);
                    window.open(status?.paymentUrl, "_self");
                  }}
                  loading={loading}
                  loader={<Roller />}
                  text={t("startAnalysis")}
                  icon={false}
                />
                <div className="calculate">{t("byPurchase")}</div>
              </div>
            </div>
            <div className="pc-only blue-blur"></div>
            <div className="pc-only whiteSection" />
          </>
        )}
      </div>
      {/* <div className="btns flex al-ce pc-only">
    <Btn
     onClick={() => {
      window.open(
       "https://help.hiflinn.com/en/articles/7257750-how-to-consult-a-lawyer",
       "_blank"
      );
     }}
     text={"consultLawyer"}
    />
    <Spacer left={158} />
    <div className="flex al-ce ">
     <PDFDownloadLink
      document={<MyDoc yourAnalysis={solution} />}
      fileName="Solution.pdf"
     >
      {() => <Btn text={"downloadAnalysis"} icon={<Download />} />}
     </PDFDownloadLink>
     <Spacer left={16} />
     <Btn
      onClick={() => {
       window.open("https://help.hiflinn.com/en", "_blank");
      }}
      text={"needHelp"}
      icon={<User />}
     />
    </div>
   </div>

   <div className="btns mob mob-only">
    <PDFDownloadLink
     document={<MyDoc yourAnalysis={solution} />}
     fileName="Solution.pdf"
    >
     {() => <Btn customClass={"downloadBtn"} text={""} icon={<Download />} />}
    </PDFDownloadLink>
    <Btn
     onClick={() => {
      window.open(
       "https://help.hiflinn.com/en/articles/7257750-how-to-consult-a-lawyer",
       "_blank"
      );
     }}
     text={"contactLawyer1"}
    />

    <Btn text={"startOver"} onClick={() => navigate("/")} icon={<User />} />
   </div> */}
    </>
  );
}

const MyDoc = ({ yourAnalysis }) => {
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.topSection} fixed>
          <Text style={styles.headerText}>
            <Text style={styles.boldText}>FLINN</Text>| Daily legal questions
            answered
          </Text>
        </View>
        <Text style={styles.text}>{yourAnalysis}</Text>
        <View style={styles.bottomSection} fixed>
          <Text style={styles.headerText}>Version 0.2</Text>
        </View>
      </Page>
    </Document>
  );
};
