import { atom } from "recoil";

export const userDataState = atom({
  key: "userDataState",
  default: {
    lang: "",
    residentCountry: "",
    residentState: "",
    incidentCountry: "",
    incidentState: "",
    email: "",
  },
});

export const questionState = atom({
  key: "questionState",
  default: "",
});

export const analysisDataState = atom({
  key: "analysisDataState",
  default: "",
});

export const analysisHeaderState = atom({
  key: "analysisHeaderState",
  default: "yourAnalysis",
});

export const progress = atom({
  key: "progress",
  default: 0,
});
