import React from "react";
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";

export default function CompareItem({
 HeaderItem,
 fee,
 speed,
 lang,
 res,
 issue,
 className,
 expanded,
}) {
 const { t } = useTranslation();
 return (
  <motion.div
   style={{
    overflow: "hidden",
    position: "relative",
   }}
   transition={{ duration: 0.25, ease: "linear" }}
   initial={{ height: expanded ? "100%" : "276px" }}
   animate={{ height: expanded ? "420px" : "276px" }}
   className={className}
  >
   {!expanded && <div className="blur" />}
   <div className="headerItem">{HeaderItem}</div>
   <div>
    <Item label={t("fee")} value={fee} />
    <Item label={t("speed")} value={speed} />
    <Item label={t("simple")} value={lang} />
    <Item label={t("download")} value={res} />
    <Item label={t("issues")} value={issue} />
   </div>
  </motion.div>
 );
}

const Item = ({ label, value }) => (
 <div className="flex jc-sb item">
  <div>{label}</div>
  <div>{value}</div>
 </div>
);
