import React from "react";
import Spacer from "../components/Spacer";
import Btn from "../components/Btn";
import { useNavigate } from "react-router-dom";
import { useRecoilState, useSetRecoilState } from "recoil";
import { progress, userDataState } from "../recoil";
import { useEffect } from "react";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { countryList } from "../util/countryList";

export default function IncidentCountry() {
 const [value, setValue] = useRecoilState(userDataState);
 const navigate = useNavigate();
 const { t } = useTranslation();
 const setProgress = useSetRecoilState(progress);
 const [isDropdownOpen, setIsDropdownOpen] = React.useState(false);

 useEffect(() => {
  setProgress(60);
 }, []);

 if (value.residentState === "") {
  navigate("/residentState");
  return <></>;
 }

 return (
  <div className="legalSection">
   <div className="headerText legalHeader">{t("countryIncident")}</div>
   <Spacer top={13} />
   <div className="smText">{t("countryIncident1")}</div>
   <Spacer top={34} />
   <div className="textAreaContainer">
    <textarea
     name="question"
     onFocus={() => setIsDropdownOpen(true)}
     onBlur={() => setIsDropdownOpen(false)}
     id="question"
     value={value.incidentCountry}
     onChange={(e) =>
      setValue({
       ...value,
       incidentCountry: e.target.value,
      })
     }
     placeholder={t("countryResidencePlaceholder")}
    />
    <div className={`languageDropdown ${isDropdownOpen ? "open" : ""}`}>
     {countryList
      .filter((country) =>
       country.name.toLowerCase().includes(value.incidentCountry.toLowerCase())
      )
      .map((country) => (
       <div
        className={`lang  ${
         value.incidentCountry.toLowerCase() === country.name.toLowerCase()
          ? "active"
          : ""
        } `}
        onClick={() => {
         setValue({
          ...value,
          incidentCountry: country.name,
         });
         setIsDropdownOpen(false);
        }}
       >
        {country.name}
       </div>
      ))}
    </div>
   </div>
   <div className="flex al-ce mob">
    <Btn
     customClass={`${value.incidentCountry < 2 ? "greyed" : ""}`}
     onClick={() => {
      if (!(value.incidentCountry.length < 2)) {
       navigate("/incidentState");
      } else {
       toast.error("Please enter a legal country");
      }
     }}
     text={"nextStep"}
    />
    <Spacer left={12} />
    <div
     className="back"
     onClick={() => {
      navigate("/same");
     }}
    >
     {t("back")}
    </div>
   </div>
  </div>
 );
}
