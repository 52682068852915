import React from "react";
import { motion } from "framer-motion";

export default function Loader({ progress, bg = "#32C850" }) {
 return (
  <motion.div
   style={{
    width: "100%",
    height: "7px",
    backgroundColor: "#E9E9E9",
    borderRadius: "10px",
    overflow: "hidden",
    content: "",
    position: "relative",
   }}
  >
   <motion.div
    initial={{
     width: 0,
     originX: "left",
    }}
    animate={{
     width: progress + "%",
     translateZ: 0,
    }}
    style={{
     position: "absolute",
     top: 0,
     left: 0,
     maxWidth: "100%",
     height: "100%",
     borderRadius: "10px",
     backgroundColor: bg,
    }}
   />
  </motion.div>
 );
}
