import React from "react";
import { useRecoilValue } from "recoil";
import { progress } from "../recoil";
import { motion } from "framer-motion";

export default function Progress() {
 const progressValue = useRecoilValue(progress);
 return (
  <motion.div
   className="progress"
   initial={{
    width: 0,
   }}
   animate={{
    width: `${progressValue}%`,
   }}
   transition={{
    duration: 0.5,
   }}
  />
 );
}
