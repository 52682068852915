import React from "react";
import Spacer from "../components/Spacer";
import Btn from "../components/Btn";
import Modal from "../components/Modal";
import { useNavigate } from "react-router-dom";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { progress, questionState, userDataState } from "../recoil";
import { useEffect } from "react";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

export default function LegalQuestion() {
 const [question, setQuestion] = useRecoilState(questionState);
 const [modal, setModal] = React.useState(false);
 const navigate = useNavigate();
 const { t } = useTranslation();
 const setProgress = useSetRecoilState(progress);

 const value = useRecoilValue(userDataState);

 useEffect(() => {
  setProgress(90);
 }, []);

 const {
  residentCountry,
  incidentCountry,
  lang,
  residentState,
  incidentState,
 } = value;

 if (lang === "") {
  navigate("/");
  return <></>;
 }
 if (residentCountry === "") {
  navigate("/residentCountry");
  return <></>;
 }
 if (residentState === "") {
  navigate("/residentState");
  return <></>;
 }
 if (incidentCountry === "") {
  navigate("/incidentCountry");
  return <></>;
 }
 if (incidentState === "") {
  navigate("/incidentState");
  return <></>;
 }

 return (
  <div className="legalSection">
   <div className="headerText legalHeader">
    {t("describe")}
    <span onClick={() => setModal(true)} className="faq">
     ?
    </span>{" "}
   </div>
   <Spacer top={13} />
   <div className="smText">{t("describeText")}</div>
   <Spacer top={34} />
   <textarea
    name="question"
    id="question"
    value={question}
    onChange={(e) => setQuestion(e.target.value)}
    placeholder={t("describePlaceholder")}
   />
   <div className="flex al-ce mob">
    <Btn
     customClass={`${question.length < 10 ? "greyed" : ""}`}
     onClick={() => {
      if (!(question.length < 10)) {
       navigate("/email");
      } else {
       toast.error("Please enter a legal question");
      }
     }}
     text={"upload"}
    />
    <Spacer left={12} />
    <div className="calculate">{t("calculate")}</div>
   </div>
   <Modal
    link={
     "https://help.hiflinn.com/en/articles/7257734-how-to-perfectly-write-legal-questions"
    }
    open={modal}
    closeModal={() => setModal(false)}
    title={t("title1")}
    text={t("yt")}
    subTitle={t("subTitle1")}
    textArr={[t("textArr1"), t("textArr2"), t("textArr3")]}
   />
  </div>
 );
}
